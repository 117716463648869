import { template as template_10e3951bb27942a0b20b10e5d8e41af5 } from "@ember/template-compiler";
const FKText = template_10e3951bb27942a0b20b10e5d8e41af5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
