import { template as template_3dbcfe3fa675453996d2cf9b927fff28 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3dbcfe3fa675453996d2cf9b927fff28(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
